import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  left: 0;
  margin-left: 20px;
  margin-top: 10px;
  div {
    width:  1.75rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? "#fff" : "#2F2B27"};
    opacity: 1;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    margin-left: ${({ open }) => open ? "2px" : 0};
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'translateX(35vw) rotate(45deg) ' : 'rotate(0)'};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => open ? 0 : 1};
      transform: ${({ open }) => open ? 'translateX(35vw) ' : 'rotate(0)'};

    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? ' translateX(35vw) rotate(-45deg)' : 'rotate(0)'};
    }
  }
  @media (max-width: 768px) {
    padding: 2.5px;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;

const Burger = () => {
  const [open, setOpen] = useState(false)
  
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open}/>
    </>
  )
}
export default Burger