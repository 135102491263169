import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  width: 50vw;
  align-items: center;
  opacity: 1;
  margin: 0;
  padding-right: 10px;
  z-index: 3;
  flex-flow: column nowrap;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
  background-color: #2F2B27;
  position: fixed;
  transform: ${({ open }) => open ? 'translateX(0%)' : 'translateX(-100%)'};
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: 5.5rem;
  margin-top: 0;
  transition: transform 0.3s ease-in-out;
  a:-webkit-any-link {
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
  }
  li {
    color: #fff;
  }
`;

const MenuItem = styled.li`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 4px;
  font-weight: bold;
  color: #434343;
`;

const RightNav = ({ open }) => {
  return (
    <Ul open={open}>
      <MenuItem><Link to="/">HOME</Link></MenuItem>
      <MenuItem><Link to="/remodel/">REMODEL</Link></MenuItem>
      <MenuItem><Link to="/painting/">PAINTING</Link></MenuItem>
      <MenuItem><Link to="/flooring/">FLOORING</Link></MenuItem>
      <MenuItem><Link to="/handyman/">HANDYMAN</Link></MenuItem>
      <MenuItem><Link to="/general/">GENERAL</Link></MenuItem>
    </Ul>
  )
}

export default RightNav