import React from "react"
import { Link } from "gatsby"
import {Container,
    Wrapper,
    Left,
    Right,
    Block,
    Title,
    List,
    MediaCon,
    IconImg,
    ByLine,
    Item,
    ByLink} from "../styles/footerStyles"
import InstaImg from "../images/instagram.png"
import FacebookImg from "../images/facebook.png";


const Footer = () => (
    <Container>
        <Wrapper>
            <Left>
                <Block>
                    <Title>JB construction</Title>
                    <List>
                        <Item>About me</Item>
                        <Item>Privacy policy</Item>
                        <Item>Contact me</Item>
                    </List>
                </Block>
                <Block>
                    <Title>Services</Title>
                    <List>
                        <Item>Painting</Item>
                        <Item>Remodeling</Item>
                        <Item>Flooring</Item>
                        <Item>Handyman</Item>
                    </List>
                </Block>
            </Left>
            <Right>
                <Title>Follow me</Title>
                <MediaCon>
                    <IconImg src={InstaImg}/>
                    <IconImg src={FacebookImg}/>
                </MediaCon>
                <ByLine>Website designed and developed by <ByLink href="https://jlte.ch">jlte.ch</ByLink></ByLine>
            </Right>
        </Wrapper>
    </Container>
)

export default Footer