import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components";
import Logo from "../images/logo.png"
import BracketImg from "../images/brace.png"
import Mobile from './Nav/Navbar';


const Header = ({ siteTitle }) => (
  <Container>
    <Mobile/>
    <Wrapper>
      <List>
        <Item to="/" style={{paddingLeft: "0px"}}>
          <LogoCon src={Logo}/>
        </Item>
        <Item to="/remodel/">Remodel</Item>
        <Item to="/painting/">Painting</Item>
        <Item to="/flooring/">Flooring</Item>
        <Item to="/handyman/">Handyman</Item>
        <Item to="/general/">General</Item>
      </List>
      <MiniWrapper>
        <Contact>Contact Me</Contact>
        <Bracket src={BracketImg}/>
      </MiniWrapper>
      
    </Wrapper>
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 4.8px;
  color: #2F2B27;
`;

const Wrapper =styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    @media (max-width: 769px) {
      display: none;
    }
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
`;

const Item = styled(Link)`
  font-size: 16px;
  padding: 15px;
  text-transform: uppercase;
  text-decoration: none;
  color: #2F2B27;
`;

const LogoCon = styled.img`
  margin:0;
  min-height: 40px;
  min-width: 60px;
`;

const MiniWrapper =styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Bracket = styled.img`
  margin:0;
  transform: scaleX(-1);
  height: 40px;
`;

const Contact = styled.div`
  font-size: 16px;
  padding: 15px;
  text-transform: uppercase;
  margin-right: -20px;
`;

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
