import { Link } from "gatsby"
import styled from "styled-components";


const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: normal;
  background-color: #2F2B27;
  color: #fff;
`;

const Wrapper =styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 80%;
    align-items: flex-start;
`;

const Left = styled.div`
    display: flex;
    flex-direction: row;
    
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 768px) {
        width: 100%;
        padding-bottom: 20px;
      }
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 50px;
    padding-bottom: 50px;
    @media (max-width: 768px) {
        width: 100%;
        align-items: flex-start;
      }
`;

const Block = styled.div`
    margin-right: 50px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 2px;
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
`;

const Item = styled(Link)`
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
`;

const MediaCon =styled.div`
    display: flex;
    flex-direction: row;
`;

const IconImg = styled.img`
    height: 20px;
    margin: 5px;
`;

const ByLine  = styled.div`
    font-size: 16px;
    align-self: flex-end;
    padding-top: 80px;
    @media (max-width: 768px) {
        align-self: flex-start;
        padding-top: 20px;
      }
`;

const ByLink = styled.a`
    color: #fff;
    text-decoration: none;
`;

export {
    Container,
    Wrapper,
    Left,
    Right,
    Block,
    Title,
    List,
    MediaCon,
    IconImg,
    ByLine,
    Item,
    ByLink
}