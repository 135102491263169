import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';

const Nav = styled.nav`
  width: fit-content;
  height: 50px;
  display: flex;
  justify-content: center;
  aligh-items: center;
  .logo {
    padding: 15px 0;
  }
  @media (min-width: 769px) {
    display: none;
  }
`

const Navbar = () => {
  return (
    <Nav>
      <Burger />
    </Nav>
  )
}

export default Navbar